import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { yupResolver } from "@hookform/resolvers/yup";
import { PageHeader, SaleAgreementModal } from "components";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PaymentSuccessSection } from "sections";
import { IPaymentFormFields, PaymentMethodsEnum } from "types";
import { paymentFormSchema } from "./PaymentFormSchema";
import { PaymentMethodFormEnum } from "./PaymentMethodsForms/WireTransferMethod";
import PaymentMethodStep from "./steps/PaymentMethodStep";

export enum PaymentFormFields {
  TermsAccepted = "termsAccepted",
}

interface PaymentFormValues {
  [PaymentFormFields.TermsAccepted]: boolean;
  [PaymentMethodFormEnum.PaymentMethod]: PaymentMethodsEnum;
}

interface PaymentFormProps {
  paymentForm: IPaymentFormFields;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ paymentForm }) => {
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const sectionOrder = document.getElementById("orderForm");
    if (paymentCompleted) {
      sectionOrder?.scrollIntoView({ behavior: "smooth" });
    }
  }, [paymentCompleted]);

  const formMethods = useForm<PaymentFormValues>({
    resolver: yupResolver(paymentFormSchema()),
    defaultValues: {
      [PaymentMethodFormEnum.PaymentMethod]: PaymentMethodsEnum.Card,
    },
  });

  const paymentMethodValue = formMethods.watch(
    PaymentMethodFormEnum.PaymentMethod
  );

  return (
    <section className="bg-white py-section" id="orderForm">
      <SaleAgreementModal
        agreements={
          paymentForm?.agreementContent
            ? documentToReactComponents(paymentForm?.agreementContent)
            : null
        }
        isModalOpen={isModalOpen}
        setModalClose={() => setModalOpen(false)}
        title="Jetson One Pre-Order Sale Agreement"
      />
      {!paymentCompleted ? (
        <div className="bg-white mx-auto w-full container">
          <PageHeader title="Payment" />
          <div className="text-center lg:mb-[98px] mb-10 max-w-[896px] mx-auto">
            <h2 className="heading-2">Order a Jetson ONE</h2>
            <p className="subheading-1 mt-6">
              Ready to take the skies with your own Jetson ONE? In just a few
              steps you can become a Jetson pilot.
            </p>
            <p className="subheading-1 mt-6">
              {paymentForm.dueTodayDescription}
            </p>
            <p className="subheading-1 mt-6">
              Fill out the form and press submit to reserve your Jetson ONE. If
              you have any questions, you can always contact our sales
              department at&nbsp;
              <a className="text-accent" href="mailto:sales@jetsonaero.com">
                sales@jetsonaero.com
              </a>
            </p>
          </div>
          <div className="">
            <div className="w-full mx-auto md:mx-0 my-10 md:my-0">
              <FormProvider {...formMethods}>
                <PaymentMethodStep
                  onModalOpen={() => setModalOpen(true)}
                  paymentMethod={paymentMethodValue}
                  setPaymentCompleted={() => setPaymentCompleted(true)}
                />
              </FormProvider>
            </div>
          </div>
        </div>
      ) : (
        <PaymentSuccessSection paymentMethod={paymentMethodValue} />
      )}
    </section>
  );
};

export default PaymentForm;
