import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox } from "components";
import { ArrowIcon } from "icons";
import { useForm } from "react-hook-form";
import { INewsletterSectionFields } from "types";
import { useMailChimpForm } from "use-mailchimp-form";
import { newsletterSectionFormSchema } from "./NewsletterSectionFormSchema";

export enum NewsletterSectionFormEnum {
  Email = "EMAIL",
  TermsAgreement = "termsAgreement",
}

interface NewsletterFormValues {
  [NewsletterSectionFormEnum.Email]: string;
  [NewsletterSectionFormEnum.TermsAgreement]?: boolean;
}

const NewsletterSection: React.FC<{
  newsletterSection: INewsletterSectionFields[];
}> = ({ newsletterSection }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<NewsletterFormValues>({
    resolver: yupResolver(newsletterSectionFormSchema()),
  });

  const termsAgreementValue = watch(NewsletterSectionFormEnum.TermsAgreement);

  const url = process.env.NEXT_PUBLIC_MAILCHIMP_URL as string;
  const { message, handleSubmit: mailChimpSubmit } = useMailChimpForm(url);
  const onSubmit = ({ EMAIL }: NewsletterFormValues) => {
    mailChimpSubmit({ EMAIL });
  };

  return (
    <section>
      <div className="container">
        {newsletterSection.map((newsletter, index) => (
          <div className="flex flex-col items-center py-section" key={index}>
            <h2 className="text-center heading-2">Be the first to know</h2>
            <p className="lg:text-xl text-1xl pt-4 lg:leading-[33px]">
              Sign up for our newsletter
            </p>
            <form
              className="w-full max-w-md newsletter_signup"
              onSubmit={handleSubmit(onSubmit)}
            >
              {message !== "Thank you for subscribing!" ? (
                <div className="flex flex-col gap-4">
                  <div className="flex lg:flex-row flex-col lg:pt-16 pt-10 w-full justify-center">
                    <div className="flex flex-col">
                      <input
                        {...register(NewsletterSectionFormEnum.Email)}
                        className="w-full lg:w-[458px] h-14 placeholder-black text-[16px] outline-0 py-[18px] pl-4 lg:mr-[24px] border-[1px] border-[#DDDDDC]"
                        placeholder="Email address"
                      />
                      <p className="text-red-600 text-[13px] pl-[18px]">
                        {errors && errors.EMAIL?.message}
                      </p>
                    </div>
                    <div>
                      <Button
                        className="lg:w-[167px] mx-auto lg:mt-0 mt-8 button-primary w-full !px-8 !py-4 lg:h-14 h-12 self-end"
                        label="Subscribe"
                        rightIcon={ArrowIcon}
                        rightIconClassName="text-accent ml-5 !w-6 !h-6"
                        type="submit"
                      />
                    </div>
                  </div>
                  <div
                    className="lg:min-w-[640px] lg:-ml-24
                  "
                  >
                    <Checkbox
                      checked={termsAgreementValue}
                      errorClassname="ml-11"
                      errors={errors}
                      label={
                        <p>
                          I have read the Privacy Policy of the owner of the
                          website https://www.jetsonaero.com/ and I accept its
                          provisions. I agree to the processing of the personal
                          data provided above by Jetson International Inc. for
                          marketing purposes. I would like to receive e-mail
                          information about promotions and special offers of
                          Jetson Company.
                        </p>
                      }
                      name={NewsletterSectionFormEnum.TermsAgreement}
                      onChange={(checked) =>
                        setValue(
                          NewsletterSectionFormEnum.TermsAgreement,
                          checked
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="bg-black rounded-[48px] mt-5 p-8">
                  <p className="text-white font-semibold lg:text-base text-[13px]">
                    Thank you for subscribing our Newsletter!
                  </p>
                </div>
              )}
            </form>
          </div>
        ))}
      </div>
    </section>
  );
};
export default NewsletterSection;
