import Head from "next/head";

interface SinglePageMetaTagsProps {
  ogImage?: string;
  title: string;
  ogDescription?: string;
}

const PageHeader: React.FC<SinglePageMetaTagsProps> = ({
  ogImage,
  title,
  ogDescription,
}) => {
  const pageTitle = `${title} | Jetson - Personal Electric Aerial Vehicle`;
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta
        content={
          ogImage ?? "https://www.jetsonaero.com/assets/images/og_image.png"
        }
        property="og:image"
      />
      <meta content={pageTitle} property="og:title" />
      <meta
        content={
          ogDescription ??
          "Your personal aircraft in aluminium and carbon fibre, powered with eight powerful electric motors."
        }
        property="og:description"
      />
      {/* Primary Meta Tags */}
      <meta content={pageTitle} name="title" />
      <meta
        content={
          ogDescription ??
          "Your personal aircraft in aluminium and carbon fibre, powered with eight powerful electric motors."
        }
        name="description"
      />
      {/* Twitter */}
      <meta content={pageTitle} property="twitter:title" />
      <meta
        content={
          ogDescription ??
          "Your personal aircraft in aluminium and carbon fibre, powered with eight powerful electric motors."
        }
        property="twitter:description"
      />
      {/* OG/Facebook */}
      <meta content={pageTitle} property="og:title" />
      <meta
        content={
          ogDescription ??
          "Your personal aircraft in aluminium and carbon fibre, powered with eight powerful electric motors."
        }
        property="og:description"
      />
    </Head>
  );
};

export default PageHeader;
